<template>
  <div>
    <hero-bar>
      <span class="capitalize"> {{ title }} </span>
      <router-link slot="right" :to="goTo" class="button">
        {{ $getTranslation('general.back') }}
      </router-link>
    </hero-bar>

    <section class="section is-main-section">
      <card-component icon="account">
         <ValidationObserver ref="observer" v-slot="{ passes }">
          <form ref="form" @submit.prevent="handleSubmit">
            <InputWithValidation
              horizontal
              rules="required"
              type="name"
              v-model="form.name"
              icon="account"
              provider="provider"
              :responseError="error"
              :label="$getTranslation('form.name.label')"
              :placeholder="$getTranslation('form.name.placeholder')"
              @keyup.native="validateUnique(form.name, 'slug')"
            />

            <b-field horizontal :label="$getTranslation('form.slug.label')">
              <b-input v-model="form.slug" type="text" disabled>{{ form.slug }}</b-input>
            </b-field>

            <b-field horizontal :label="$getTranslation('form.description.label')" class="capitalize">
              <b-input v-model="form.description" type="textarea">{{ form.description }}</b-input>
            </b-field>
            <hr />

            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary"  @click="passes(submit)">{{ $getTranslation('form.submit') }}</b-button>
                </div>
                <div class="control" v-if="item !== null">
                  <b-button type="is-primary is-outlined" @click="reset">{{ $getTranslation('form.reset') }}</b-button>
                </div>
              </b-field>
            </b-field>
          </form>
        </ValidationObserver>
      </card-component>
    </section>
    <section class="section is-main-section mb-6" v-if="$route.meta.type === 'roles' && form.allPermissions !== null">
      <h5 class="title is-5">Apply permissions:</h5>
      <card-component
        class="has-table has-mobile-sort-spaced"
        icon="account-multiple"
      >
        <div v-if="!loading">
          <checkbox-picker v-model="form.permissions" :data="(form.allPermissions)" :table="true" :perPage="15" :paginated="true" @checked="checked" type="is-primary"  />
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
// Form for crete and edit roles and permissions (they both have same db
// structure)
import { mapGetters, mapActions } from "vuex"
import { adminUrl, baseUrl } from '@/api-routes.js'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-create',
  props: ['slug'],
  mixins: [FormTemplateMixin],

  data () {
    return {
      title: this.$route.meta.routeName,
      endpoint: adminUrl,
      adminEndpoint: adminUrl,
      // formSession are saved old valus needed for reset
      formSession: {
        name: null,
        description: null,
        // slug is only readable, cannot be changed
        slug: null,
        allPermissions: null,
      }
    }
  },
  computed: {
    form: {
      get: function () {
        let form = {};

        // form is empty if user is created, has props from formSession
        form = this.formSession

        // On create user, slug is not defined, form is empty
        if (typeof this.slug === "undefined" || this.slug === null) {
          // this.formSession = form
          return form
        }

        // Assign data from backend to the form, and save values in formSession
        // for reset action
        this.setFormValues (form)
        // TODO: only admins and owner might see roles and permissions
        /*if(this.isLoggedUser.roles !== 'admin' || this.isLoggedUser.roles !== 'owner') {
          form.allRoles = null
          form.allPermissions = null
        }*/
        return form
      },
      // setter needed to reset the form
      set: function(form) {
        this.setFormValues (form)
      }
    },

    goTo() {
      return '/' + this.moduleName
    }
  },
  methods: {
    checked (slug, selected) {
      // Extend backend - add action to update permissions
      let action = 'attach'
      if(!selected) {
        action = 'detach'
      }

      let form = {}
      form.slug = this.slug
      form.action = action
      form.permission = slug
      // Update database - detach from pivot table role-permissions
      // update permission_user too
      let payload = {
        method: this.formMethod,
        endpoint: this.endpoint,
        data: form
      }
      this.saveItem(payload)
        .then(response => {
          // console.log("form submit: ", response);
        })
        .catch(error => {
          console.log("ERROR form submit: ", error)
        });
      // console.log('payload: ', payload, selected)

    },

    /**
     * submit will dynamically handle create or update action according to the
     * method defined
     */
    submit () {
      let payload = {
        method: this.formMethod,
        data: this.fetchData()
      }

      /*if(typeof(this.slug) !== 'undefined' && this.slug.length) {
        payload.endpoint = this.endpoint + '/' + this.slug
      } else {
        payload.endpoint = this.endpoint
        // console.log('payload: ', payload)
      }*/
      payload.endpoint = this.endpoint
      // console.log(this.slug, this.endpoint)
      this.saveItem(payload)
        .then(response => {
          // console.log("form submit: ", baseUrl);
          // clean objects
          this.formSession =  {}
          // location.reload();
          this.redirectTo();
        })
        .catch(error => {
          console.log("ERROR form submit: ", error)
        });
    },

    reset () {
      this.form = this.formSession
    },

    setFormValues (form) {
      if(this.item !== null) {
        let item = this.item.data
        // console.log(item)
        form.name = item.name
        form.slug = item.slug
        form.description = item.description
        if (item.hasOwnProperty('allPermissions') && item.allPermissions !== null) {
          form.allPermissions = item.allPermissions
        }
        if (item.hasOwnProperty('permissions') && item.permissions !== null) {
          form.permissions = this.buildArray(item.permissions)
        }
        this.formSession = form
      }
    },

    /**
     * TODO: check it !!!
     * validate unique field (usually title, name)
     *
     * @param  {string} value
     * @param  {string} field type of the field (name, title, slug)
     *
     * @return {void}
     */
    validateUnique (value, field) {
      // console.log('validateUnique: ', value , 'name: ', this.$refs.provider);
      this.form.slug = this.generateSlug(value)
      // if value is not null
      if (value !== this.name) {
        let endpoint = this.adminEndpoint + this.moduleName + '/unique'

        if (typeof field === "undefined" || field === null) {
          // Some forms have title, like posts
          field = 'name';
        }

        let data = {
          type: field,
          value: value,
          oldValue: this.slug
        };

        let payload = {
          endpoint: endpoint,
          data: data
        };

        this.checkUnique (payload)
          .then(response => {
            console.log('res error returned', response)
          })
          .catch(error => {
            console.log('error returned', error)
          })
      }
    },
  },

  created () {
    let payload = {}
    // console.log('form created: ', this.slug, this.$route.meta.type, this.$route.meta.routeName)
    this.moduleName = this.$route.meta.type

    // console.log('slug? ', this.slug, this.formMethod, 'typeof this.slug or null - not working!!')
    if(typeof(this.slug) !== "undefined" && this.slug.length) {
      this.endpoint += this.moduleName + '/' + this.slug
      // this.title = this.$getTranslation('form.general.edit') + ' ' +  this.$getTranslation('general.views.' + this.$route.meta.type , 0)
      payload = {
        endpoint: this.endpoint,
        type: this.moduleName
      }
      this.formMethod = 'put'
      this.fetchItem(payload)
      // console.log( ' slug known: ', this.slug)
    } else {
      // create new, nothing to fetch
      this.endpoint += this.moduleName
      payload = {
        endpoint: this.endpoint,
        type: this.moduleName
      }
      console.log('payload: ', payload)
    }
    // console.log('form method? ', this.formMethod)
  }
}
</script>
<style scoped></style>
